

.container {
  width: 100%;
}

.skills {
  text-align: right;
  padding-top: 1px;
  padding-bottom: 1px;
  color: white;
  background-color: #ddd;

}

.html {width: 90%; background-color: #04AA6D;}
.javascript {width: 95%; background-color: #2196F3;}
.nodejs {width: 96%; background-color: #f44336;}
.reactjs {width: 70%; background-color: #808080;}
.python {width: 75%; background-color: #995151;}
.git {width: 75%; background-color: #4a8f74;}
.mysql {width: 80%; background-color: #3b0a2d;}
.mongodb {width: 95%; background-color: #79363b;}
.docker {width: 55%; background-color: #b520f0;}
.graphql {width: 30%; background-color: #9b4ee43d;}
.typescript {width: 85%; background-color: #7db3ae;}